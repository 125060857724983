import classNames from 'classnames';

import { useCustomPortfolioStore } from '../../services/customPortfolioStore';
import { FundAllocationRow, ProductAttributeExtended } from '../../types';
import { ReactComponent as CheckIcon } from 'assets/checkmark2.svg';
import { ReactComponent as CrossIcon } from 'assets/x-icon.svg';
import { useForm as useRoboAdviceForm } from 'features/roboAdvice/adviceSession/form/services/form';
import { ProductAttributeType } from 'features/roboAdvice/adviceSession/shared/constants';
import { useAdviceSessionStore } from 'features/roboAdvice/adviceSession/shared/services/adviceSessionStore';
import Tooltip from 'features/shared/components/tooltip';
import { processImgURL } from 'features/shared/utils/processImgURL';
import { getTranslation } from 'features/shared/utils/translations';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  numberCell: {
    textAlign: 'right'
  },
  center: {
    textAlign: 'center'
  },
  iconHeader: {
    height: '40px',
    width: '40px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center'
  },
  checkIcon: {
    fill: theme.successColor
  },
  crossIcon: {
    fill: theme.errorNegativeColor
  }
}));

type Arguments = {
  productAttributes: ProductAttributeExtended[];
  namespaceId: number;
};

const useFundAllocationPreferenceCriteriaData = ({
  productAttributes,
  namespaceId
}: Arguments) => {
  const classes = useStyles();
  const { sustainabilityData } = useCustomPortfolioStore();
  const { customAttributesData } = useAdviceSessionStore();
  const {
    roboAdvice: {
      sustainability: {
        sustainabilityPreference: { config: sustainabilityPreferenceConfig }
      }
    }
  } = useCustomerConfig();
  const {
    values: { sustainability }
  } = useRoboAdviceForm();

  const selectedPreferenceCriteria = sustainability?.preferenceCriteria;

  const preferenceCriteriaTableHeaders = [
    ...sustainabilityPreferenceConfig
      .filter(item => selectedPreferenceCriteria?.includes(item.id))
      .map(({ icon, id, title }) => ({
        component: icon ? (
          <Tooltip
            content={getTranslation(title)}
            infoTooltip
            key={id}
            trigger={
              <div
                style={{ backgroundImage: `url(${processImgURL(icon)})` }}
                className={classes.iconHeader}
              />
            }
            triggerButtonStyles={{ margin: 'auto' }}
          />
        ) : (
          getTranslation(title)
        ),
        className: classes.center
      })),
    ...productAttributes.map(({ label, className }) => ({
      title: getTranslation(label),
      className
    }))
  ];

  const createPreferenceCriteriaTableData = (
    fundAllocationRows: FundAllocationRow[]
  ): FundAllocationRow[] => {
    if (!sustainabilityData || !customAttributesData) return fundAllocationRows;

    return fundAllocationRows.map(category => {
      const { instruments } = category;

      return {
        ...category,
        instruments: instruments.map(instrument => {
          const foundInstrumentFundAnalysis =
            sustainabilityData?.fundAnalysis.find(
              f => f.ticker === instrument.id
            );

          if (!foundInstrumentFundAnalysis) return instrument;

          const preferenceValues = sustainabilityPreferenceConfig
            .filter(item => selectedPreferenceCriteria?.includes(item.id))
            .map(({ title, id }) => ({
              name: getTranslation(title),
              status: foundInstrumentFundAnalysis.preferenceValues[id]
            }));

          const customAttributeData = customAttributesData?.[namespaceId]?.find(
            ({ ticker }) => ticker === instrument.id
          );

          return {
            ...instrument,
            createAdditionalData: ({ className }) => (
              <>
                {preferenceValues.map(({ status, name }) => (
                  <td
                    key={name}
                    className={classNames(className, classes.center)}
                  >
                    {status ? (
                      <CheckIcon className={classes.checkIcon} />
                    ) : (
                      <CrossIcon className={classes.crossIcon} />
                    )}
                  </td>
                ))}

                {productAttributes.map(({ type, name }) => {
                  if (type === ProductAttributeType.text) {
                    return (
                      <td className={className} key={name}>
                        {customAttributeData?.productAttributeText?.[name]}
                      </td>
                    );
                  }

                  if (type === ProductAttributeType.binary) {
                    return (
                      <td
                        className={classNames(className, classes.center)}
                        key={name}
                      >
                        {customAttributeData?.productAttributeBinary?.[name] ? (
                          <CheckIcon className={classes.checkIcon} />
                        ) : (
                          <CrossIcon className={classes.crossIcon} />
                        )}
                      </td>
                    );
                  }

                  return null;
                })}
              </>
            )
          };
        })
      };
    });
  };

  return {
    preferenceCriteriaTableHeaders,
    createPreferenceCriteriaTableData
  };
};

export default useFundAllocationPreferenceCriteriaData;
